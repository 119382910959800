import React from "react";
import { LOGO_IMAGE_URL } from "../Constants";

function Footer() {
  return (
    <div className="flex flex-col w-full justify-center items-center pb-8">
    </div>
  );
}

export default Footer;
